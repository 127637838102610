import request from "@/utils/request";

const baseUrl = '/'

// 随机图片
export function getCatRandomCatImage() {
    return request({
        url: 'https://api.thecatapi.com/v1/images/search',
        method: 'get'
    })
}
// one一句-随机语录
export function getRandomSentence() {
    return request({
        url: 'https://api.xygeng.cn/one',
        method: 'get'
    })
}

