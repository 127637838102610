import Vue from 'vue'
import Vuex from 'vuex'
import article from "@/store/modules/article";
import user from "@/store/modules/user";
import common from "@/store/modules/common";
import settings from "@/store/modules/settings";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    article,
    user,
    common,
    settings
  },
  // 使用：this.$store.getters.total即可获取到，这里将不同module的getter都放在此处，则访问时就不用指定对应module的命名空间了
  getters: {
    recentArticleList: state => state.article.recentArticleList,
    searchValue: state => state.article.searchValue,
    categoryId: state => state.article.categoryId,
    search: state => state.article.search,
    mobile: state => state.common.mobile,
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    name: state => state.user.name,
    nickName: state => state.user.nickName,
  }
})
