// 在这里定义全局变量
const state = {
    searchValue: null,
    categoryId: null,
    search: false,
    recentArticleList: JSON.parse(localStorage.getItem('recentArticleList')) || []
}
/*
// 没有用
function getRecentArticleListFromLocalStorage() {
    let list = []
    try {
        list = JSON.parse(localStorage.getItem('recentArticleList'))
    } catch {
        console.log('reset')
        localStorage.setItem('recentArticleList', [])
    }
    console.log(list)
    return list;
}*/
//这里是set方法，比如对该数据的修改、增加、删除等等。
//组件调用this.$store.commit('Set_Article_List', list) 将list赋值给这里的state中对应的变量
const mutations = {
    SET_SEARCH_VALUE: (state, value) => {
        state.searchValue = value
    },
    SET_CATEGORY_ID: (state, id) => {
        state.categoryId = id
    },
    SET_SEARCH: (state, val) => {
        state.search = val
    },
    SET_RECENT_ARTICLE_LIST(state, list) {
        state.recentArticleList = list
        localStorage.setItem('recentArticleList', JSON.stringify(list))
    }
}
//异步处理
//组件中使用this.$store.dispatch('[namespace/]setQueryParams',list)  []中为可选参数，当配置了使用命名空间后就需要指定一个命名空间
const actions = {
    setSearchValue(context, value) {
        context.commit('SET_SEARCH_VALUE', value)
    },
    // 使用结构语法从 context 对象中取到 commit。 context拥有和store实例具有相同方法和属性，如state、mutation、commit、getters等
    setCategoryId({ commit }, id) {
        commit('SET_CATEGORY_ID', id)
    },
    setSearch({ commit }, val) {
        commit('SET_SEARCH', val)
    },
    setRecentArticleList({ commit }, list) {
        commit('SET_RECENT_ARTICLE_LIST', list)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
