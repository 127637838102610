<template>
<div class="footer-container">
  <div class="item-container">
    <img alt="风铃博客" height="20" src="@/assets/img/logo.png">
    <!--<el-image alt="风铃博客" :src="logoUrl" style="height: 80px"></el-image>-->
    <div>Copyright © 2024 风铃笔记</div>
  </div>
  <div style="text-align: center">
    <div style="display: flex; flex-direction: row; align-items: center;">
      <el-image fit="contain" :src="beianLogo" alt="公安联网备案" style="width: 16px;"></el-image>
      <a href="https://beian.mps.gov.cn/#/query/webSearch?code=52050202001564" rel="noreferrer" target="_blank">贵公网安备52050202001564</a>
      <a href="https://beian.miit.gov.cn" target="_blank" style="margin-left: 20px;">黔ICP备2024020702号</a>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      logoUrl: require('@/assets/img/logo.png'),
      beianLogo: require('@/assets/img/beian-logo.png')
    }
  }
}
</script>

<style lang="less" scoped>
.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #000000;
  font-family: Arial;
  font-size: 14px;
  letter-spacing: 1px;

  .item-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

a {
  text-decoration: none;
  color: #000;
}

/* 状态一: 未被访问过的链接 */
a:link {
  text-decoration: none;
  color: #000;
}

/* 状态二: 已经访问过的链接 */
a:visited {
  text-decoration: none;
  color: #000;
}

/* 状态三: 鼠标划过(停留)的链接(默认红色) */
a:hover {
  text-decoration: none;
  color: #1296db;
}
/* 状态四: 被点击的链接 */
a:active {
  text-decoration: none;
  color: #000;
}

</style>
