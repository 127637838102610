<template>
  <div v-if="!isCollapse">
    <div class="header-container">
      <div class="left-bar">
        <div class="logo" @click="goHome" title="风铃笔记">
          <!--<img alt="风铃博客logo" height="50" src="@/assets/img/fengling-note.png">-->
          <img alt="风铃博客" height="50" src="@/assets/img/logo.png">
        </div>
        <div class="nav">
          <el-menu :default-active="activeMenu"
                   mode="horizontal"
                   active-text-color="#409EFF"
                   @select="handleSelect">
            <el-menu-item index="/home">
              <i class="el-icon-s-home"></i>
              <span slot="title">首页</span>
            </el-menu-item>
            <el-submenu index="category">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span>分类</span>
              </template>
              <template v-for="(item, index) in categoryOptions">
                <el-menu-item v-if="!item.children" :key="index" :index="'/search?category='+item.categoryId+''">
                  {{item.name}}
                </el-menu-item>
                <el-submenu :index="'submenu'+index" v-else>
                  <template slot="title">{{item.name}}</template>
                  <template v-for="(subItem, itemIndex) in item.children">
                    <el-menu-item :key="itemIndex" :index="'/search?category='+subItem.categoryId+''">
                      {{subItem.name}}
                    </el-menu-item>
                  </template>
                </el-submenu>
              </template>

            </el-submenu>
            <el-menu-item index="/about">
              <i class="el-icon-info"></i>
              <span slot="title">关于</span>
            </el-menu-item>
          </el-menu>
        </div>
      </div>
      <div class="search-bar">
        <el-input v-model="searchValue" clearable placeholder="搜点什么" @keyup.enter.native="handleQuery">
          <el-button title="点击搜索或刷新" slot="append" icon="el-icon-search" circle @click="handleQuery"></el-button>
        </el-input>
      </div>
      <div class="right-bar">
        <template v-if="isLogin">
          <!--<el-button icon="el-icon-message">消息</el-button>-->
          <el-button icon="el-icon-star-off" @click="collection">收藏</el-button>
          <el-button icon="el-icon-edit-outline" @click="write">写作</el-button>
          <el-dropdown style="margin: 0 10px" placement="bottom" @command="handleCommand">
            <el-image :src="avatar" style="cursor: pointer; border-radius: 30px; height: 45px;">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item style="text-align: center; font-weight: bold;">{{ nickName }}</el-dropdown-item>
              <el-dropdown-item icon="el-icon-user" command="space">个人中心</el-dropdown-item>
              <el-dropdown-item icon="el-icon-s-operation" command="admin">后台管理</el-dropdown-item>
              <el-dropdown-item icon="el-icon-circle-close" command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <el-button type="primary" plain @click="login" v-if="!isLogin">登录/注册</el-button>
      </div>
    </div>
  </div>
  <div style="width: 100%" v-else>
    <div class="mobile-top-bar">
      <el-button :icon="drawer?'el-icon-s-fold':'el-icon-s-unfold'" @click="drawer = !drawer"></el-button>
      <div class="search-bar">
        <el-input v-model="searchValue" clearable placeholder="搜点什么" @keyup.enter.native="handleQuery">
          <el-button slot="append" icon="el-icon-search" circle @click="handleQuery"></el-button>
        </el-input>
      </div>
      <el-button type="primary" plain v-if="!isLogin" @click="login">登录/注册</el-button>
      <el-dropdown v-if="isLogin" style="margin: 0 10px" @command="handleCommand">
        <el-image :src="avatar" style="cursor: pointer; border-radius: 30px; height: 45px;"></el-image>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item style="text-align: center; font-weight: bold;">{{ nickName }}</el-dropdown-item>
          <el-dropdown-item icon="el-icon-user" command="space">个人中心</el-dropdown-item>
          <el-dropdown-item icon="el-icon-s-operation" command="admin">后台管理</el-dropdown-item>
          <el-dropdown-item icon="el-icon-circle-close" command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-drawer
        :visible.sync="drawer"
        direction="ltr"
        size="60%"
        append-to-body
        :before-close="handleClose">
      <div class="mobile-header-container">
        <div class="mobile-menu-bar">
          <div class="logo" @click="goHome">
            <img alt="风铃笔记" height="50" width="138" src="@/assets/img/fengling-note.png">
          </div>
          <div class="nav">
            <el-menu :default-active="activeMenu"
                     mode="vertical"
                     :default-openeds="defaultOpenArray"
                     active-text-color="#409EFF"
                     @select="handleSelect">
              <el-menu-item index="/home">
                <i class="el-icon-s-home"></i>
                <span slot="title">首页</span>
              </el-menu-item>
              <el-submenu index="category">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>分类</span>
                </template>
                <template v-for="(item, index) in categoryOptions">
                  <el-menu-item v-if="!item.children" :key="index" :index="'/search?category='+item.categoryId+''">
                    {{item.name}}
                  </el-menu-item>
                  <el-submenu :index="'submenu'+index" v-else>
                    <template slot="title">{{item.name}}</template>
                    <template v-for="(subItem, itemIndex) in item.children">
                      <el-menu-item :key="itemIndex" :index="'/search?category='+subItem.categoryId+''">
                        {{subItem.name}}
                      </el-menu-item>
                    </template>
                  </el-submenu>
                </template>

              </el-submenu>
              <el-menu-item index="/about">
                <i class="el-icon-info"></i>
                <span slot="title">关于</span>
              </el-menu-item>
            </el-menu>
          </div>
        </div>
        <div class="mobile-operate-bar">
          <template v-if="isLogin">
            <!--<div class="operator-item">-->
            <!--  <i class="el-icon-message"> 消息</i>-->
            <!--</div>-->
            <div class="operator-item" @click="collection">
              <i class="el-icon-star-off"> 收藏</i>
            </div>
            <div class="operator-item" @click="write">
              <i class="el-icon-edit-outline"> 写作</i>
            </div>
          </template>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {getCategoryList} from "@/api/category";
import { mapGetters } from 'vuex'
import {getToken} from "@/utils/auth";

export default {
  name: "Header",
  data() {
    return {
      isCollapse: false,
      categoryOptions: [],
      drawer: false,
      defaultOpenArray: [],
      // 文章相关
      searchValue: null,
      categoryId: null,
      prodServer: 'http://admin.fengling.work'
      // prodServer: 'http://localhost:80'
    }
  },
  computed: {
    isLogin() {
      return this.$store.getters.name !== ''
    },
    ...mapGetters([
        'nickName',
        'avatar'
    ]),
    // nickName() {
    //   return this.$store.getters.nickName;
    // },
    // avatar() {
    //   return this.$store.getters.avatar;
    // },
    activeMenu() {
      let path = this.$route.path;
      let query = this.$route.query
      if (query.category) {
        path = path + '?category=' + query.category
        this.categoryId = query.category
        // 设置分类id
        this.$store.dispatch('article/setCategoryId', this.categoryId)
      }
      return path;
    },
    mobile() {
      return this.$store.getters.mobile
    }
  },
  created() {
    this.getCategoryOptions();
    // this.isCollapse = this.isMobile() || false
    this.isCollapse = this.mobile || false
  },
  watch: {
    // searchValue(newVal) {
    //   if (!newVal) {
    //     console.log(newVal)
    //     this.$store.dispatch("article/setSearch", false)
    //     console.log(this.$store.getters.search)
    //   }
    // }
  },
  methods: {
    // 登录
    login() {
      this.goto('/login');
    },
    // 获取分类数据
    getCategoryOptions() {
      getCategoryList().then(res => {
        // 将分类数据转为树形结构
        this.categoryOptions = this.getCategoryTree(res.data)
      }).catch(() => {
        this.$modal.msgError('获取分类数据失败')
      });
    },

    // 搜索文章
    handleQuery() {
      // 如果搜索内容为空，则刷新当前路由页面
      if (!this.searchValue) {
        this.$router.go(0)
        return
      }
      // 设置查询参数
      this.$store.dispatch('article/setSearchValue', this.searchValue)
      this.$store.dispatch('article/setSearch', true)
      this.$router.push('/search').catch(()=>{})
    },
    // 菜单选中事件
    handleSelect(key) {
      // 关闭抽屉
      this.drawer = false;
      this.$router.push({
        path: key
      }).catch(() => {})  // .catch(() => {})用来捕获连续点击同一个菜单项时，控制台的报错：Avoided redundant navigation to current location ...
    },
    handleCommand(command) {
      switch (command) {
        case 'space':
          this.space();
          break;
        case 'admin':
          this.admin();
          break;
        case 'logout':
          this.logout();
      }
    },
    space() {
      // window.open(this.prodServer + "/user/profile", "_blank");

      const token = getToken()
      window.open(this.prodServer + "/fromFront?redirect=/user/profile&user=" + token, "_blank");
    },
    admin() {
      // 携带token信息进行跳转，管理端接收请求参数后保存token，变为登录状态
      const token = getToken()
      window.open(this.prodServer + "/fromFront?user=" + token, "_blank");

      // 用户端和管理端的token都是存在Cookie里面，而同一个域名的不同端口会共享Cookie，
      // 因此这里将用户端和管理端的token-key设置为一样，则用户端登录后，管理端也可以直接变为登录状态
      // window.open(this.prodServer, "_blank");
    },
    collection() {
      // window.open(this.prodServer + "/collections/collection", "_blank");

      const token = getToken()
      window.open(this.prodServer + "/fromFront?redirect=/collections/collection&user=" + token, "_blank");
    },
    write() {
      // window.open(this.prodServer + "/blog/article/write", "_blank");

      const token = getToken()
      window.open(this.prodServer + "/fromFront?redirect=/blog/article/write&user=" + token, "_blank");
    },

    logout() {
      this.$modal.confirm('确认要退出登录吗？').then(() => {
        this.$store.dispatch('FedLogOut')
      }).catch(()=>{});
    },

    goHome() {
      this.goto('/home');
    },
    goto(path) {
      this.$router.push(path).catch(()=>{})
    },
    // 判断是否为移动设备
    isMobile() {
      return this.$store.getters.mobile
    },
    handleClose(done) {
      // 折叠所有菜单  不折了吧
      // 设置菜单打开数组为空，即可控制菜单都折叠起来
      // this.defaultOpenArray = [];
      done();
    },
    /**
     * 将分类数据转为树形结构
     */
    getCategoryTree(arr) {
      const map = {};
      const result = [];
      arr.forEach(item => {
        map[item.categoryId] = item;
        if (item.parentId === 0) {
          result.push(item);
        }
      });
      arr.forEach(item => {
        if (item.parentId !== 0) {
          const parent = map[item.parentId];
          if (parent === undefined) {
            return
          }
          if (!parent.children) {
            parent.children = [];
          }
          parent.children.push(item);
        }
      });
      return result;
    }
  }
}
</script>

<style lang="less" scoped>
.header-container {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left-bar {
    display: flex;
    align-items: center;

    .logo {
      //width: 50px;
      height: 50px;
      cursor: pointer;
      margin: 0 10px;
    }
    .search-bar {
      display: flex;
      flex-direction: row;
    }
  }

  .right-bar {
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

  }
}

.mobile-top-bar {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

}
.mobile-header-container {

}

.mobile-operate-bar {
  display: flex;
  flex-direction: column;

  .operator-item {
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
  }
  .operator-item:focus, .operator-item:hover {
    background: #ecf5ff;
  }
}
</style>
