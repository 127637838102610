import Cookies from 'js-cookie'

// const tokenKey = 'Front-Token'
const tokenKey = 'Admin-Token'

export function getToken() {
    return Cookies.get(tokenKey)
}

// 不设置过期时间，则Cookie的有效期默认为当前会话，即关闭浏览器后Cookie被删除
export function setToken(token) {
    return Cookies.set(tokenKey, token)
}

// 设置过期时间
export function setTokenExpired(token, expiresTime) {
    expiresTime = expiresTime || 7
    // expires属性默认单位为天。也可以传递一个时间戳，例如设置30分钟后过期：new Date(new Date().getTime() + 30 * 60 * 1000)
    return Cookies.set(tokenKey, token, { expires: expiresTime })
}

export function removeToken() {
    return Cookies.remove(tokenKey)
}
