import axios from 'axios'
import { Notification, MessageBox, Message, Loading } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
//对axios进行二次封装,将axios导入进来
import config from '@/config'

// 是否显示重新登录
export let isRelogin = { show: false };

// 判断当前环境是开发环境还是生产环境
const baseUrl = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
//1.利用axios对象的方法create,去创建一个axios实例
//2.requests就是axios,只不过稍微配置一下
const requests = axios.create({
  //配置对象
  //基础路径，发请求的时候，路径当中会出现api，不用你手写
  baseURL: baseUrl,
  //请求时间超过10秒
  timeout:10000,
  // headers: {'content-type': 'application/json;charset=utf-8'}
  // headers: {'Content-Type': 'application/x-www-form-urlencoded'}
});

//请求拦截器:在发请求之前，请求拦截器可以检测到,可以在请求发出去之前做一些事情
requests.interceptors.request.use((config)=>{
  //config是个配置对象，对象里面有一个属性很重要，headers请求头
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + getToken()
  }

  return config;
},  function (error) {
  console.log(error)
  return Promise.reject(error);
});

//响应拦截器：包含两个函数（一个是成功返回的函数，一个是失败的返回的函数）
requests.interceptors.response.use((res)=>{
  const code = res.data.code || 200;
  const msg = errorCode[code] || res.data.msg || errorCode['default']
  // 二进制数据则直接返回
  if (res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer') {
    return res.data
  }
  if (code === 401) {
    if (!isRelogin.show) {
      MessageBox.confirm('该操作需要登录，您可以继续留在该页面，或者去登录', '系统提示', { confirmButtonText: '去登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
        isRelogin.show = false;
        store.dispatch('LogOut').then(() => {
          location.href = '/index';
        })
      }).catch(() => {
        isRelogin.show = false;
      });
    }
    return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
  } else if (code !== 200) {
    Notification.error({ title: msg })
    return Promise.reject('error')
  } else {
    return res.data
  }
},(error)=>{
  console.log('err' + error)
  let { message } = error;
  if (message == "Network Error") {
    message = "后端接口连接异常";
  } else if (message.includes("timeout")) {
    message = "系统接口请求超时";
  } else if (message.includes("Request failed with status code")) {
    message = "系统接口" + message.substr(message.length - 3) + "异常";
  }
  Message({ message: message, type: 'error', duration: 5 * 1000 })
  return Promise.reject(error)
});

//对外暴露requests(axios的二次封装)
export default requests;

