// 在这里定义全局变量
const state = {
    // mobile: false,
    mobile: navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i),
}
//这里是set方法，比如对该数据的修改、增加、删除等等。
const mutations = {
    SET_MOBILE: (state, value) => {
        state.isMobile = value
    },
}
//异步处理
const actions = {
    setMobile(context, value) {
        context.commit('SET_MOBILE', value)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
