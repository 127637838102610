<template>
  <el-container>
    <!--<el-header>-->
    <!--</el-header>-->
    <Header :class="scrollDown ? 'header-hide' : 'header-show'"/>
    <el-main>
      <!--如果滚动条是整个document自身的那么此时target不用设置-->
      <el-backtop style="z-index: 2000" :bottom="60">
        <div>
          <i class="iconfont icon-huojian" style="font-size: 50px"></i>
        </div>
      </el-backtop>
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" :key="$route.path" v-slot="{ Component }">
          <transition name="fade">
            <component :is="Component" />
          </transition>
        </router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" :key="$route.path"/>
      <el-footer>
        <Footer/>
      </el-footer>
    </el-main>
  </el-container>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "Layout",
  components: {
    Footer,
    Header
  },
  data() {
    return {
      // scrollTop: '',
      oldScrollTop: 0,
      scrollDown: false,
    };
  },
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // 滚动条距文档顶部的距离
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;
      this.scrollDown = scrollStep >= 0;
    },
  },

  watch: {
    /* 使用监听的方式实现向下滑动隐藏header */
    // 监听 scrollTop 值的变化
    // scrollTop(newValue, oldValue){
    //   // 等新值大于100的时候再做变化（优化一下）
    //   console.log(newValue, oldValue)
    //   // 等新值大于100的时候再做变化（优化一下）
    //   if (newValue > 50) {
    //     if (newValue > oldValue) {
    //       console.log('向下滚动')
    //       this.scrollDown = true
    //     } else {
    //       console.log('向上滚动')
    //       this.scrollDown = false
    //     }
    //   }
    // }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}
</script>

<style lang="less" scoped>
.el-container {
  min-height: 100vh;

  display: flex;
  flex-direction: column;

  .el-header {
    width: 100%;
    padding: 0;
    background: white;
  }

  .el-main {

    padding: 10px 10px 0 10px;
    background-color: whitesmoke;
    //min-height: calc(100vh - 60px);

    //max-height: calc(100vh - 60px);
    //overflow-y: auto;
  }
  .el-footer {
    min-height: 80px;
    padding: 0;
    height: auto !important;
    background: #fff;

    display: grid;
  }
}

//.header {
//  position: fixed;
//  top: 0; /* 初始时 header 向上偏移 50px */
//  left: 0;
//  z-index: 3000;
//  width: 100%;
//  background: white;
//  transition: top 0.3s ease-in-out; /* 设置过渡效果 */
//}
//.scrolling-down .header {
//  top: 0; /* 向下滚动时 header 出现 */
//}


.header-hide {
  transform: translateY(-60px);
  transition: height 0.5s ease-out;
}
.header-show {
  position: fixed;
  top: 0; /*初始向上偏移0*/
  left: 0;
  z-index: 2000;
  width: 100%;
  background: white;
  //transition: all 0.5s;
  /* 设置过渡效果 all:要过度的css属性（width、height等）  0.3s:过渡的时间长度(s, ms) ease-in-out:过度的速度曲线，linear线性、ease-in缓入、ease-out缓出、ease-in-out缓入缓出 */
  transition: all 0.3s ease-in-out;
}

</style>
