/**
 * 获取指定闭区间的随机数
 * @param min 最小值
 * @param max 最大值
 * @returns {number}
 */
export function getRandomNum(min, max) {
    let result;
    if (min <= max) {
        result = Math.floor(Math.random() * (max - min + 1) + min);
    } else {
        result = Math.floor(Math.random() * (min - max + 1) + max);
    }
    return result;
}

/**
 * 获取随机冷色
 * 颜色设计思路：R值 === 50，0 <= G值 <= 255, 150 <= B值 <= 255,这样随机出来的冷色更加柔和，不会产生刺眼的感觉
 * @returns {string} 返回rgba颜色
 */
export function getRandomCoolHexColor() {
    let r = 50
    let g = getRandomNum(0,255)
    let b = getRandomNum(150, 255)
    return '#' + r.toString(16) + g.toString(16) + b.toString(16);
}

/**
 * 获取随机暖色
 * 颜色设计思路：R值 === 255，0 <= G值 <= 230, B值 === 50 ,这样随机出来的暖色更加柔和，不会产生刺眼的感觉
 * @param alpha 透明度
 * @returns {string} 返回rgba颜色
 */
export function getRandomWarmHexColor(alpha) {
    const r = 255;
    const g = getRandomNum(0, 230);
    const b = 50;
    // return `rgba(${r},${g},${b},${alpha})`;
    return '#' + r.toString(16) + g.toString(16) + b.toString(16);
}
