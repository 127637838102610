import request from "@/utils/request";

const baseUrl = '/front/blog/articles'

// 查询文章list
export function getArticleList(query) {
    return request({
        url: baseUrl + '/list',
        method: 'get',
        params: query
    })
}

// 查询文章详情
export function getArticleDetailById(articleId) {
    return request({
        url: baseUrl + '/' + articleId,
        method: 'get'
    })
}

// 增加文章浏览量
export function increaseArticleViewsNum(articleId) {
    return request({
        url: baseUrl + '/addView/' + articleId,
        method: 'post'
    })
}

// 查询文章数、浏览量等基础信息
export function getBaseInfo() {
    return request({
        url: baseUrl + '/info',
        method: 'get'
    })
}

// 查询自己对文章的点赞状态
export function getLikeStatus(articleId) {
    return request({
        url: baseUrl + '/status/' + articleId,
        method: 'get'
    })
}

// 点赞
export function likeArticle(articleId) {
    return request({
        url: baseUrl + '/like/' + articleId,
        method: 'post'
    })
}

