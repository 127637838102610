<template>
  <el-card style="margin-bottom: 10px;" body-style="padding: 10px 20px 0 20px;">
    <el-row style="margin-bottom: 20px; background: white; border-radius: 15px;">
      <el-col :span="4">
        <!--<div style="width: 180px;">-->
        <div class="image" @click="viewDetail(article.articleId)">
          <!--无封面时使用随机图片 || 'https://picsum.photos/200?random='+article.articleId-->
          <el-image :src="baseUrl + article.img" lazy alt="" >
            <div slot="error" class="image-slot">
              <!--<img src="@/assets/img/404.jpg" alt="" width="100%" height="100%">-->
              <!-- 无封面时使用随机图片 -->
              <img :src="'https://picsum.photos/200?random='+article.articleId" alt="" width="100%" height="100%">
              <span class="tip">随机图片</span>
            </div>
          </el-image>
        </div>
      </el-col>
      <el-col :span="20">
        <div style="cursor: pointer" @click="viewDetail(article.articleId)">
          <h2>{{ article.title }}</h2>
          <p>{{ article.articleAbstract }}</p>
        </div>

        <div class="base-info">
          <i class="el-icon-user-solid"> {{ article.author }}</i>•
          <i class="el-icon-menu"> {{ article.categoryName }}</i>•
          <i class="iconfont icon-date"> {{ article.updateTime }}</i>•
          <i class="iconfont icon-view"> {{ article.viewNum }}</i>•
          <i class="iconfont icon-dianzan"> {{ article.likeNum }}</i>
        </div>
        <div>
          <i class="iconfont icon-tags">
            <template v-for="(item, index) in article.tagsList" :index="index">
              <el-tag size="mini" effect="dark" :color="randomHexColors[index]" :style="{'margin-left': '10px', 'border-color': randomHexColors[index]}">
                {{item.tagName}}
              </el-tag>
            </template>
          </i>
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import {getRandomCoolHexColor, getRandomWarmHexColor} from "@/utils/randomColor";
import config from "@/config/index"
export default {
  name: "ArticleItem",
  props: ['article'],
  data() {
    return {
      randomHexColor: null,
      randomHexColors: [],
      baseUrl: config.baseUrl.pro
    }
  },
  created() {
    this.getRandomHexColor()
  },
  methods: {
    getRandomHexColor() {
      for (let i = 0; i < this.article.tagsList.length; i++) {
        let color = getRandomCoolHexColor();
        // let color = getRandomWarmHexColor();
        this.randomHexColors.push(color)
      }
    },
    // 查看文章详情
    viewDetail(articleId) {
      this.$router.push({
        path: '/detail',
        query: {
          articleId
        }
      }).catch(()=>{})
    }
  }
}
</script>

<style lang="less" scoped>
.article-item {
  //width: 50vw;
  width: 100%;
  margin-bottom: 20px;
}

.image {
  padding: 10px;
}
.image:hover {
  cursor: pointer;
}
p {
  word-break:break-all;
  word-wrap:break-word;
  white-space:pre-wrap;
}
.tip {
  font-size: 10px;
  color: #999;
}
</style>
