import request from "@/utils/request";

const baseUrl = '/front/blog/category'

export function getCategoryList() {
    return request({
        url: baseUrl + '/list',
        method: 'get'
    })
}
